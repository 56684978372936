<template>
    <div
        class="base-video"
        tabindex="-1"
        aria-hidden="true"
    >
        <video
            ref="videoPlayer"
            autopictureinpicture="false"
            playsinline
            preload="auto"
            autoplay
            muted
            loop
            :src="activeVideo"
            :poster="poster"
            type="video/mp4"
            @click="togglePlaystate"
        />
        <button
            v-if="showButton"
            class="base-video__play-button"
            :aria-label="videoIsPlaying ? 'Pause video' : 'Play video'"
            @click="togglePlaystate"
        >
            <BaseIcon :icon="videoIsPlaying ? 'pause' : 'play'" />
        </button>
    </div>
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core';

const isMobile = useMediaQuery('(max-width: 900px)');

const props = defineProps({
    videoUrl: {
        type: String,
        default: ''
    },
    videoUrlMobile: {
        type: String,
        default: ''
    },
    poster: {
        type: String,
        default: ''
    },
    autoplay: {
        type: Boolean,
        default: false
    },
    showButton: {
        type: Boolean,
        default: true
    }
});

const videoPlayer = ref(null);
const videoIsPlaying = ref(props.autoplay);

const activeVideo = computed(() => {
    return isMobile.value && props.videoUrlMobile ? props.videoUrlMobile : props.videoUrl;
});

defineExpose({
    togglePlaystate
});

function togglePlaystate() {
    if (videoPlayer.value.paused) {
        videoPlayer.value.play();
        videoIsPlaying.value = true;
    } else {
        videoPlayer.value.pause();
        videoIsPlaying.value = false;
    }
}
</script>

<style lang="less" src="./BaseVideo.less"></style>
